import { Controller } from 'stimulus'
import Splide from '@splidejs/splide'

import '@splidejs/splide/dist/css/splide.min'

export default class extends Controller {
  connect () {
    const splide = new Splide(this.element, {
      perPage: 4,
      rewind: true,
      gap: 10,
      arrows: false,
      classes: {
        pagination: 'splide__pagination bottom-minus-2rem',
        page: 'splide__pagination__page MultipleSlider__Pagination__Page'
      },
      breakpoints: {
        640: {
          perPage: 2
        }
      }
    })

    splide.mount()
  }
}

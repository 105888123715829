import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit', 'select', 'selectWithCreate', 'multiSelect', 'fileField', 'inputs']
  static values = {
    create: String
  }

  connect () {
    if (this.selectTargets.length > 0) this.loadSelect()
    if (this.selectWithCreateTargets.length > 0) this.loadSelectWithCreate()
    if (this.hasFileFieldTarget) this.loadDragAndDropZone()
    if (this.multiSelectTargets.length > 0) this.loadMultiSelect()
    this.verifyForm()
  }

  verifyForm () {
    if (this.submitTargets.length === 0) return

    const inputsArray = this.inputsTargets.map(
      (item) => item.value.trim().length === 0
    )

    if (inputsArray.includes(true)) {
      this.submitTarget.disabled = true
      return
    }
    this.submitTarget.disabled = false
  }

  submitForm () {
    this.element.submit()
  }

  // Use dataset to custom your select:
  //   - search : enabled search bar with minimum value
  //     ex : data: { shared__form_target: 'select', search: 0 }
  loadSelect () {
    this.selectTargets.forEach((item) => {
      $(item).select2({
        minimumResultsForSearch: item.dataset.search ? item.dataset.search : Infinity,
        width: '100%'
      }).on('select2:select', function () {
        const event = new Event('change', { bubbles: true })
        item.dispatchEvent(event)
      })
    })
  }

  loadSelectWithCreate () {
    this.selectWithCreateTargets.forEach((item) => {
      $(item).select2({
        minimumResultsForSearch: item.dataset.search ? item.dataset.search : Infinity,
        width: '100%',
        tags: true,
        createTag: function (params) {
          return {
            id: params.term,
            text: params.term,
            newOption: true // Add this for new options
          }
        }
      }).on('select2:select', function () {
        const event = new Event('change', { bubbles: true })
        item.dispatchEvent(event)
      })
    })
  }

  loadMultiSelect () {
    this.multiSelectTargets.forEach((item) => {
      $(item).multiselect({
        enableFiltering: item.dataset.search ? item.dataset.search : true,
        filterPlaceholder: 'Rechercher',
        includeSelectAllOption: true,
        selectAllText: item.dataset.selectAllText ? item.dataset.selectAllText : 'Tous selectionner',
        includeFilterClearBtn: false,
        enableCaseInsensitiveFiltering: true,
        dropUp: false,
        nonSelectedText: item.dataset.noneSelectedText ? item.dataset.noneSelectedText : 'Selectionner des éléments',
        widthSynchronizationMode: 'always',
        buttonWidth: '100%',
        maxHeight: 200,
        nSelectedText: item.dataset.nSelectedText ? item.dataset.nSelectedText : 'éléments',
        allSelectedText: `Tous les ${item.dataset.nSelectedText ? item.dataset.nSelectedText : 'éléments'}`,
        numberDisplayed: 1
      })
    })
  }

  loadDragAndDropZone () {
    $('.drag-and-drop-zone').ezdz({
      text: this.multipleFilesValue ? 'Déposer un/des fichier(s)' : 'Déposer un fichier',
      classes: {
        main: 'ezdz-dropzone',
        enter: 'ezdz-enter',
        reject: 'ezdz-reject',
        accept: 'ezdz-accept',
        focus: 'ezdz-focus'
      },

      accept: function (file) {
        $('.ezdz-dropzone div').prepend('<i class="fa fa-file" style="font-size: 3em; margin-bottom: 0.3em;"></i>')
      },
      reject: function (file, errors) {
        if (errors.mimeType) {
          alert(file.name + ' must be an image.')
        }

        if (errors.maxWidth) {
          alert(file.name + ' must be width:600px max.')
        }

        if (errors.maxHeight) {
          alert(file.name + ' must be height:400px max.')
        }
      }
    })

    $('.ezdz-dropzone div').prepend('<i class="fa fa-cloud-upload" style="font-size: 3em; margin-bottom: 0.3em;"></i>')
    $('.ezdz-dropzone').addClass('w-100')
  }
}

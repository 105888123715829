import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class extends Controller {
  static values = {
    employeesUrl: String
  }

  static targets = ['employeeId', 'userProId']

  changeUserPro (event) {
    this.#getEmployees()
  }

  async #getEmployees () {
    const response = await getQuery(`${this.employeesUrlValue}?user_pro=${this.userProIdTarget.value}`)
    this.#updateEmployeeOptions(response)
  }

  #updateEmployeeOptions (employees) {
    const select = this.employeeIdTarget
    this.#removeAllOptions(select)

    for (let i = 0, j = employees.length; i < j; i++) {
      const employee = employees[i]
      const text = `${employee.first_name} ${employee.last_name}`
      const value = employee.id
      this.#addOptions(select, text, value)
    }
  }

  #addOptions (select, text, value) {
    const newOption = new Option(text, value)
    select.add(newOption, undefined)
  }

  #removeAllOptions (select) {
    while (select.options.length > 0) {
      select.remove(0)
    }
  }
}

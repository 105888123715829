import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tableHeading', 'tableRow']

  navigationTo (event) {
    if (!event.params.url) return

    window.location.href = event.params.url
  }
};

import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'
export default class extends Controller {
  static targets = ['submitButton']

  connect () {
    this.checkSubmitButton()
  }

  clickCardSimple (e) {
    const cardClicked = e.currentTarget
    const inputClicked = cardClicked.children[0]
    const PreviousCardsChecked = document.querySelector('.CardRadio__Input:checked')

    if (inputClicked === PreviousCardsChecked) {
      if (!document.querySelector(`[for='${PreviousCardsChecked.name}'] #counter`)) {
        this.changeValueInput(inputClicked)
      }
    } else {
      this.removePreviousCardsChecked(PreviousCardsChecked)
      this.changeValueInput(inputClicked)
    }

    const inputChildCounter = document.querySelector(`[for='${inputClicked.name}'] #counter`)
    if (inputChildCounter && document.querySelector(`[for='${inputClicked.name}'] [data-select='employeeCost']`)) {
      this.computeNewPrice(inputClicked)
      inputChildCounter.classList.remove('d-none')
    }
    this.checkSubmitButton()
  }

  changeValueInput (input) {
    if (input.value === 'true') {
      input.value = false
      input.checked = false
    } else {
      input.value = true
      input.checked = true
    }
  }

  removePreviousCardsChecked (PreviousCardsChecked) {
    if (PreviousCardsChecked) {
      this.changeValueInput(PreviousCardsChecked)
      const previousChildCounter = document.querySelector(`[for='${PreviousCardsChecked.name}'] #counter`)
      if (previousChildCounter) {
        previousChildCounter.classList.add('d-none')
        this.removeCounterDatas(PreviousCardsChecked)
        if (document.querySelector(`[for='${PreviousCardsChecked.name}'] [data-select='employeeCost']`)) {
          this.computeNewPrice(PreviousCardsChecked)
        }
      }
    }
  }

  checkSubmitButton () {
    const CardsChecked = document.querySelector('.CardRadio__Input:checked')
    if (CardsChecked) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  removeCounterDatas (PreviousCardsChecked) {
    document.querySelector(`[for='${PreviousCardsChecked.name}'] [data-select='labelFieldCounter']`).textContent = '1 enfant'
    document.querySelector(`[for='${PreviousCardsChecked.name}'] [data-select='inputFieldCounter']`).value = 1
  }

  async computeNewPrice (card) {
    const numberOfChild = document.querySelector(`[for='${card.name}'] [data-select='inputFieldCounter']`).value

    const response = await getQuery(`/espace-salarie/affiliations-collective/prix-cotisation-formule?cotisation_structure=${card.name}&number_of_child=${numberOfChild}`)
    this.updatePriceText(card, response)
  }

  updatePriceText (card, response) {
    document.querySelector(`[for='${card.name}'] [data-select='employeeCost']`).textContent = response.employee_price
    document.querySelector(`[for='${card.name}'] [data-select='userProCost']`).textContent = response.user_pro_price
  }
}

import { Controller } from 'stimulus'
import { deleteQuery, postQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['cardList', 'emptyCard', 'fileInput', 'employeeSickWorkStopDocumentTemplateIdHiddenInput', 'subtitleContentWithoutFile', 'subtitleContentWithFile']

  createDocument (event) {
    const file = event.target.closest('form').querySelector('input[name="file"]').files[0]

    this.#createQuery(event, file)
  }

  drop (event) {
    const { files } = event.dataTransfer
    const file = files[0]
    this.#createQuery(event, file)
  }

  deleteDocument (event) {
    const url = event.params.url
    const target = event.target
    this.#deleteQuery(url)

    const card = target.closest('.uploaded-img-zone')
    this.#hiddenCard(card)
    this.#hiddenCardList()
    this.#updateSubtitle()
  }

  async #createQuery (event, file) {
    const form = event.target.closest('form')
    const url = form.action
    const employeeSickWorkStopDocumentTemplateId = this.employeeSickWorkStopDocumentTemplateIdHiddenInputTarget.value
    const formData = this.#formData(employeeSickWorkStopDocumentTemplateId, file)

    const data = await postQuery(url, formData, true)
    const response = await data.json()
    this.#addNewCard(response)
    this.#resetForm(form)
    this.#updateSubtitle()
    this.#showAlert()
  }

  #formData (employeeSickWorkStopDocumentTemplateId, file) {
    const formData = new FormData()
    formData.append('employee_sick_work_stop_document[employee_sick_work_stop_document_template_id]', employeeSickWorkStopDocumentTemplateId)
    formData.append('employee_sick_work_stop_document[file]', file)

    return formData
  }

  #addNewCard (response) {
    this.#showCardList()
    const newCard = this.#newCard(response)
    this.emptyCardTarget.insertAdjacentHTML('afterend', newCard.innerHTML)
  }

  #newCard (response) {
    const cardClone = this.emptyCardTarget.cloneNode(true)
    cardClone.querySelector('.file-link').href = response.file.link
    cardClone.querySelector('.file-name').innerHTML = response.file.name
    cardClone.querySelector('.file-size').innerHTML = response.file.size
    cardClone.querySelector('.file-link-destroy').setAttribute('data-employee-sick-work-stop-document-templates--show--component--item-component-url-param', response.file.destoy_link)

    return cardClone
  }

  #resetForm (form) {
    const fileInput = form.querySelector('input[name="file"]')
    fileInput.value = null
  }

  async #deleteQuery (url) {
    await deleteQuery(url)
  }

  #updateSubtitle () {
    if (this.#cardListShowCount() <= 0) {
      this.#hiddenSubtitleContentWithFile()
      this.#showSubtitleContentWithoutFile()
    } else {
      this.#hiddenSubtitleContentWithoutFile()
      this.#showSubtitleContentWithFile()
    }
  }

  #hiddenSubtitleContentWithoutFile () {
    this.#hiddenSubtitleContent(this.subtitleContentWithoutFileTarget)
  }

  #showSubtitleContentWithoutFile () {
    this.#showSubtitleContent(this.subtitleContentWithoutFileTarget)
  }

  #hiddenSubtitleContentWithFile () {
    this.#hiddenSubtitleContent(this.subtitleContentWithFileTarget)
  }

  #showSubtitleContentWithFile () {
    this.#showSubtitleContent(this.subtitleContentWithFileTarget)
  }

  #showSubtitleContent (target) {
    target.classList.remove('d-none')
    target.classList.add('d-inline-block')
  }

  #hiddenSubtitleContent (target) {
    target.classList.remove('d-inline-block')
    target.classList.add('d-none')
  }

  #hiddenCard (card) {
    card.classList.add('d-none')
  }

  #showCardList () {
    this.cardListTarget.classList.remove('d-none')
  }

  #hiddenCardList () {
    if (this.#cardListShowCount() <= 0) {
      this.cardListTarget.classList.add('d-none')
    }
  }

  #cardListShowCount () {
    const allCardList = this.cardListTarget.querySelectorAll('.card')
    const cardListHidden = this.cardListTarget.querySelectorAll('.d-none')
    return allCardList.length - cardListHidden.length
  }

  #showAlert () {
    const alertDocument = document.getElementById('alert_document')

    if (alertDocument) {
      alertDocument.classList.remove('d-none')

      setTimeout(() => {
        alertDocument.classList.add('d-none')
      }, 15000)
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'theme', 'period', 'periodRange']

  connect () {
    this.initializeDateRangePicker()
  }

  initializeDateRangePicker () {
    $('.daterangepickerjs').daterangepicker({
      autoUpdateInput: false,
      locale: {
        format: 'DD/MM/YYYY',
        cancelLabel: 'Annuler'
      }
    })
    $('.daterangepickerjs').on('apply.daterangepicker', function (ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'))
      $('#period').val('')
      $('#company-idea-search-form').submit()
    })
    $('.daterangepickerjs').on('cancel.daterangepicker', function (ev, picker) {
      $(this).val('')
      $('#company-idea-search-form').submit()
    })
  }

  userProChanged () {
    this.formTarget.submit()
  }

  themeChanged () {
    this.formTarget.submit()
  }

  periodChanged () {
    this.periodRangeTarget.value = ''
    this.formTarget.submit()
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select']
  static values = { url: String }
  connect () {
    this.selectTargets.forEach((item) => {
      $(item).select2({
        language: {
          noResults: function (params) {
            return 'Aucun résultat'
          }
        },
        width: '100%',
        minimumResultsForSearch: Infinity
      }).on('select2:select', function () {
        const event = new Event('change', { bubbles: true })
        item.dispatchEvent(event)
      })
    })
  }

  replaceContent (event) {
    event.preventDefault()
    event.stopPropagation()
    fetch(
      `${this.urlValue}?user_pro_id=${this.selectTarget.value}`,
      { headers: { accept: 'application/json' } }
    ).then(response => response.text()).then(html => $('#resultat').html(html))
  }
}

import { Controller } from 'stimulus'
import Typed from 'typed.js'

export default class extends Controller {
  static values = {
    strings: Array
  }

  connect () {
    const typed = new Typed(this.element, { // eslint-disable-line
      strings: this.stringsValue,
      typeSpeed: 80,
      backSpeed: 50,
      backDelay: 1100,
      loop: true
    })
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.#addDragAndDrop()
    this.#addPrependInDropzone()
  }

  submitForm () {
    $('#upload-files').click()
  }

  #addDragAndDrop () {
    $('.drag-and-drop-zone').ezdz({
      text: 'Déposer un fichier',
      classes: {
        main: 'ezdz-dropzone ezdz-dropzone-cyan-option',
        enter: 'ezdz-enter',
        reject: 'ezdz-reject',
        accept: 'ezdz-accept',
        focus: 'ezdz-focus'
      },
      accept: function (file) {
        this.#addPrependInDropzone()
      },
      reject: function (file, errors) {
        if (errors.mimeType) {
          alert(file.name + ' must be an image.')
        }

        if (errors.maxWidth) {
          alert(file.name + ' must be width:600px max.')
        }

        if (errors.maxHeight) {
          alert(file.name + ' must be height:400px max.')
        }
      }
    })
  }

  #addPrependInDropzone () {
    $('.ezdz-dropzone div').prepend('<i class="fa fa-cloud-upload" style="font-size: 3em; margin-bottom: 0.3em;"></i>')
  }
}

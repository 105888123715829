import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'form',
    'container',
    'submit',
    'textField',
    'attachment',
    'fileUploaded',
    'messages',
    'templateSelect'
  ]

  connect () {
    if (this.hasTemplateSelectTarget) {
      $(this.templateSelectTarget).select2({
        placeholder: 'Modèles de messages',
        width: '100%',
        allowClear: true,
        language: {
          noResults: () => 'Aucun résultat'
        }
      }).on('change', this.handleTemplateChange.bind(this))
    }
  }

  reset () {
    this.formTarget.reset()
    this.textFieldTarget.value = ''
  }

  handleTemplateChange (event) {
    const selectedOption = event.target.options[event.target.selectedIndex]
    const templateContent = selectedOption.dataset.content || ''
    this.textFieldTarget.value = templateContent
    this.disableSubmit()
  }

  disableSubmit () {
    this.submitTarget.disabled = this.textFieldTarget.value.trim() === ''
  }

  updateFile (event) {
    const fileName = event.target.files[0].name
    this.attachmentTarget.insertAdjacentHTML('beforebegin', `<i class="fa fa-file me-3 tooltip-tag" title="${fileName}" data-feed-messages--component-target="fileUploaded"></i>`)
    this.attachmentTarget.classList.add('d-none')
  }
}
